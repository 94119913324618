import { Component, ViewChild, ViewEncapsulation, AfterViewInit, OnInit, ElementRef } from '@angular/core';
import { DBAccessor } from '../../db/DBAccessor';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppComponent } from '../../app/app.component';
import { MatSort } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

import DateUtil from '../../util/DateUtil.js';


@Component({
  selector: 'customer-reportpage',
  templateUrl: './CustomerReportPage.component.html',
  styleUrls: ['./CustomerReportPage.component.css']
  //encapsulation: ViewEncapsulation.None
})

export class CustomerReportPageComponent implements OnInit{

    searchHidden = true;
    showDrafts = false;
    snackBar
    allROsArray = []
    allInvoicesArray = []
    RODataSource = new MatTableDataSource(this.allROsArray);
    invoicesDataSource = new MatTableDataSource(this.allInvoicesArray);
    subscriptionContainer = new Subscription();
    newObjRouterLink = "create";
    isScreenBusy = false;
    isQuerying = false;

    selectedDocumentType = "";
    selectedCustomer = "";
    selectedPublication = "";
    selectedDateRangeOption = "currYear";
    startDate = new Date();
    endDate = new Date();

    displayROList = false;
    displayInvoiceList = false;

    //addresseeSearchValue = '';
    //clientSearchValue = '';
    customerSearchValue = '';
    publicationSearchValue = '';
    
    //addresseeSearchInput: ElementRef;
    //clientSearchInput: ElementRef;
    customerSearchInput: ElementRef;
    publicationSearchInput: ElementRef;

    customersList = []
    customersFilteredList = [];
    publicationsList = []
    publicationsFilteredList = [];
    ROAddressees = new Set(); // Publications (To the Manager)
    ROClients = new Set(); // Clients
    invoiceClients = new Set(); // Clients
    publicationsSet = new Set();

    selectedCustomers = {};
    selectedCustomersArr = [];
    selectedCustomersStr = "- No filter -";

    selectedPublications = {};
    selectedPublicationsArr = [];
    selectedPublicationsStr = "- No filter -";
    
    allROColumnDefinitions = ['rowNum','billNo','clientName','dimensions','billDate','insertionDate','publicationsStr','releaseType','isInvoiceGenerated','netAmount']
    allInvoiceColumnDefinitions = ['rowNum','billNo','clientName','dimensions','billDate','insertionDateRange','publicationsStr','releaseType','isPaymentMade','netAmount']
    
    reportObj = {
      documentType : "",
      client : "",
      publication : "",
      dateRangeStr : "",
      recordCount : "",
      grossAmount : 0,
      discounts : 0,
      surcharges : 0,
      gstAmount : 0,
      netAmount : 0
    }
    
    //@ViewChild(MatSort, {static: true}) sortM: MatSort;
    //@ViewChild('MatSortInvoice', {static: true}) sortM_Invoice: MatSort;

    @ViewChild('publicationSearchInput', {static: false}) set publicationSearchInputSet(content: ElementRef) {
      this.publicationSearchInput = content;     
    }

    @ViewChild('customerSearchInput', {static: false}) set customerSearchInputSet(content: ElementRef) {
      this.customerSearchInput = content;     
    }

    @ViewChild('ROListTable', {static: false}) ROListTableElement: MatTable<any>;
    @ViewChild('InvoiceListTable', {static: false}) InvoiceListTableElement: MatTable<any>;

    sortM : MatSort
    @ViewChild(MatSort, {static: false}) set sortM_function(content: MatSort) {
      this.sortM = content;
      if(content){
        this.RODataSource.sort = this.sortM;
        this.invoicesDataSource.sort = this.sortM;
      }
          
    }
    
    

    constructor(private route: ActivatedRoute, private dbAccessor: DBAccessor, private _snackBar: MatSnackBar,
                 private appComponent: AppComponent, private titleService: Title){
      //this.searchHidden = true;
      titleService.setTitle("Reports");
    }

    ngOnInit(){
      this.subscriptionContainer.add(this.route.data.subscribe((data) => {
        this.showDrafts = data.showDrafts;
        if(this.showDrafts){
          this.newObjRouterLink = '../Invoice/create';
        }
        //this.loadContents();
        this.loadAddresseesClients();
      }));
    }

    fillROTable(ROResults){
      
      let promiseChain = Promise.resolve();
      this.appComponent.setScreenBusyState(true);

      promiseChain = promiseChain.then(() => {
        let res = ROResults;
        let displayDocsArr = [];
        if(res.success){
          res.docs.forEach((resDoc)=>{
            let displayDoc : any = {
              id : resDoc._id,
              rev : resDoc._rev,
              billNo : resDoc.header.billNo,
              billDate : resDoc.header.billDate,
              clientName : resDoc.header.client.name,
              dimensions: '-',
              isInvoiceGenerated : resDoc.header.isInvoiceGenerated,
              netAmount : resDoc.totalObj && resDoc.totalObj.netAmount || 0
            };
            let insertionDates = new Set();
            let publicationSet = new Set();
            
            // Get Insertions total count
            let totalInsertionCount = 0;
            let isTextDate = false;
            resDoc.items.forEach((resDocItem) => {
                let newItemDate : Date;
                if(resDoc.header.releaseType == "print" || resDoc.header.releaseType == "printClassified" || resDoc.header.releaseType == "printFreeform"){
                  if((!resDoc.columnHeaderDetails || !resDoc.columnHeaderDetails['insertionDate'])
                         || !resDoc.columnHeaderDetails['insertionDate'].isCustom)
                    newItemDate = new Date(resDocItem.insertionDate);
                  else{
                    isTextDate = true;
                    newItemDate = null;
                  }
                }
                else if(resDoc.header.releaseType == "radio" || resDoc.header.releaseType == "others"){
                  if(!isTextDate){
                    if(resDocItem.campaignDate instanceof Date && !isNaN(resDocItem.campaignDate))
                      newItemDate = new Date(resDocItem.campaignDate);  
                    else{
                      isTextDate = true;
                      newItemDate = null;
                    }
                  }
                  else
                    newItemDate = null;
                }
                else newItemDate = null;
                if(newItemDate)
                  insertionDates.add(newItemDate);
                publicationSet.add(resDocItem.publication);
                totalInsertionCount += resDocItem.insertionCount;
                

              // Setting dimension text
              if(resDocItem.width && resDocItem.height){
                if(displayDoc.dimensions == '-')
                  displayDoc.dimensions = resDocItem.width + " x " + resDocItem.height;
                else
                  displayDoc.dimensions += " (+)";
              }
            });

            // Get insertion date range
            if(!isTextDate){
              let maxItemDate = new Date(Math.max.apply(null,Array.from(insertionDates))).toLocaleDateString("en-IN");
              let minItemDate = new Date(Math.min.apply(null,Array.from(insertionDates))).toLocaleDateString("en-IN");
              let itemDateRangeStr;
              if(maxItemDate === minItemDate)
                itemDateRangeStr = minItemDate;
              else
                itemDateRangeStr = minItemDate + ' - ' + maxItemDate;
              displayDoc.itemDateRangeStr = itemDateRangeStr;
            }
            else
              displayDoc.itemDateRangeStr = resDoc.items[0].campaignDateStr;

            // Release Type
            let outReleaseType: string;
            if(resDoc.header.releaseType == "print")
              displayDoc.releaseType = "Print";
            else if(resDoc.header.releaseType == "printClassified")
                displayDoc.releaseType = "Print (Classified)";
            else if(resDoc.header.releaseType == "printFreeform")
                displayDoc.releaseType = "Print (Freeform)";
            else if(resDoc.header.releaseType == "radio")
              displayDoc.releaseType = "Radio";
            else if(resDoc.header.releaseType == "others")
              displayDoc.releaseType = "Others";

            // Put total insertion count
            displayDoc.insertionCount = totalInsertionCount;

            // Put publication list
            let publicationsStr;
            let publicationsArr = Array.from(publicationSet);
            if(publicationSet.size == 1)
              publicationsStr = publicationsArr[0];
            else
              publicationsStr = publicationsArr[0] + '(+ ' + (publicationsArr.length - 1) + ' others)';
            displayDoc.publicationsStr = publicationsStr;
            displayDocsArr.push(displayDoc);
          });
        }
        this.allROsArray = displayDocsArr;
        this.RODataSource.data = this.allROsArray;
        this.RODataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'billNo':
              let finYearStr, finYear, serialNumStr, serialNum;
              let formatType = "";
              if(item[property].split('/')[0] == "ES"){ // New convention
                finYearStr = item[property].split('/')[1] || "20-21";
                finYear = parseInt(finYearStr.split('-')[0]);
                let serialNumStrArr = item[property].split('/');
                serialNum = parseInt(serialNumStrArr[serialNumStrArr.length - 1]);
                formatType = "new";
              }
              else{
                finYearStr = item[property].split('/')[1] || "20-21";
                finYear = parseInt(finYearStr.split('-')[0]);
                serialNumStr = item[property].split('/')[0] || "ES1";
                serialNum = parseInt(serialNumStr.split('ES')[1]);
                formatType = "old";
              }
              let priority = finYear * 2000 + serialNum;
              if(formatType == "new")
                priority += 2000;
              return priority;
            default: return item[property];
          }
        };
        //this.RODataSource.sort = this.sortM;
      });
      
      promiseChain = promiseChain.then(() => {
        this.isScreenBusy = false;
        this.appComponent.setScreenBusyState(false);
      });

      return promiseChain;
    }

    fillInvoiceTable(invoiceResults){
      let promiseChain = Promise.resolve();
      this.appComponent.setScreenBusyState(true);

      promiseChain = promiseChain.then(() => {
        let res = invoiceResults;
        let displayDocsArr = [];
        if(res.success){
          res.docs.forEach((resDoc)=>{
            if(resDoc.header.isDraft == true){ // Filtering drafts according to setting
              return;
            }
            let displayDoc : any = {
              id : resDoc._id,
              rev : resDoc._rev,
              billNo : resDoc.header.billNo,
              billDate : resDoc.header.billDate,
              clientName : resDoc.header.addressee.name,
              dimensions: '-',
              isPaymentMade : resDoc.header.isPaymentMade,
              netAmount : resDoc.totalObj && resDoc.totalObj.netAmount || 0
            };
            let insertionDates = new Set();
            let publicationSet = new Set();

            // Mark drafts
            if(resDoc.header.isDraft){
              displayDoc.billNo = displayDoc.billNo + ' [Draft]'
            }
            
            // Get Insertions total count
            let totalInsertionCount = 0;
            let isTextDate = false;
            resDoc.items.forEach((resDocItem) => {
                let newItemDate : Date;
                if(resDoc.header.releaseType == "print" || resDoc.header.releaseType == "printClassified" || resDoc.header.releaseType == "printFreeform"){
                  if((!resDoc.columnHeaderDetails || !resDoc.columnHeaderDetails['insertionDate'])
                         || !resDoc.columnHeaderDetails['insertionDate'].isCustom)
                    newItemDate = new Date(resDocItem.insertionDate);
                  else{
                    isTextDate = true;
                    newItemDate = null;
                  }
                }
                else if(resDoc.header.releaseType == "radio" || resDoc.header.releaseType == "others"){
                  if(!isTextDate){
                    if(resDocItem.campaignDate instanceof Date && !isNaN(resDocItem.campaignDate))
                      newItemDate = new Date(resDocItem.campaignDate);  
                    else{
                      isTextDate = true;
                      newItemDate = null;
                    }
                  }
                  else
                    newItemDate = null;
                }
                else newItemDate = null;
                if(newItemDate)
                  insertionDates.add(newItemDate);
                  
                // Setting dimension text
                if(resDocItem.width && resDocItem.height){
                  if(displayDoc.dimensions == '-')
                    displayDoc.dimensions = resDocItem.width + " x " + resDocItem.height;
                  else
                    displayDoc.dimensions += " (+)";
                }
                publicationSet.add(resDocItem.publication);
                totalInsertionCount += resDocItem.insertionCount;
            });

            // Get insertion date range
            if(!isTextDate){
              let maxItemDate = new Date(Math.max.apply(null,Array.from(insertionDates))).toLocaleDateString("en-IN");
              let minItemDate = new Date(Math.min.apply(null,Array.from(insertionDates))).toLocaleDateString("en-IN");
              let itemDateRangeStr;
              if(maxItemDate === minItemDate)
                itemDateRangeStr = minItemDate;
              else
                itemDateRangeStr = minItemDate + ' - ' + maxItemDate;
              displayDoc.itemDateRangeStr = itemDateRangeStr;
            }
            else
              displayDoc.itemDateRangeStr = resDoc.items[0].campaignDateStr;

            // Release Type
            let outReleaseType: string;
            if(resDoc.header.releaseType == "print")
              displayDoc.releaseType = "Print";
            else if(resDoc.header.releaseType == "printClassified")
                displayDoc.releaseType = "Print (Classified)";
            else if(resDoc.header.releaseType == "printFreeform")
                displayDoc.releaseType = "Print (Freeform)";
            else if(resDoc.header.releaseType == "radio")
              displayDoc.releaseType = "Radio";
            else if(resDoc.header.releaseType == "others")
              displayDoc.releaseType = "Others";

            // Put total insertion count
            displayDoc.insertionCount = totalInsertionCount;

            // Put publication list
            let publicationsStr;
            let publicationsArr = Array.from(publicationSet);
            if(publicationSet.size == 1)
              publicationsStr = publicationsArr[0];
            else
              publicationsStr = publicationsArr[0] + '(+ ' + (publicationsArr.length - 1) + ' others)';
            displayDoc.publicationsStr = publicationsStr;
            displayDocsArr.push(displayDoc);
          });
        }
        this.allInvoicesArray = displayDocsArr;
        //this.InvoiceListTableElement.renderRows();
        this.invoicesDataSource.data = this.allInvoicesArray;
        this.invoicesDataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'billNo':
              let finYearStr, finYear, serialNumStr, serialNum;
              let IDStr = item[property].split(' ')[0];
              let formatType = "";
              if(IDStr.split('/')[0] == "ES"){ // New convention
                finYearStr = IDStr.split('/')[1] || "20-21";
                finYear = parseInt(finYearStr.split('-')[0]);
                let serialNumStrArr = IDStr.split('/');
                serialNum = parseInt(serialNumStrArr[serialNumStrArr.length - 1]);
                formatType = "new";
              }
              else{
                finYearStr = IDStr.split('/')[1] || "20-21";
                finYear = parseInt(finYearStr.split('-')[0]);
                serialNumStr = IDStr.split('/')[0] || "ES-1";
                if(this.showDrafts){
                  serialNum = parseInt(serialNumStr.split('ES-DRAFT-')[1]);
                }
                else
                  serialNum = parseInt(serialNumStr.split('ES-')[1]);
                formatType = "old";
              }
              let priority = finYear * 2000 + serialNum;
              if(formatType == "new")
                priority += 2000;
              return priority;
              
            default: return item[property];
          }
        };
        //this.invoicesDataSource.sort = this.sortM;
      
      });
      promiseChain = promiseChain.then(() => {
        this.isScreenBusy = false;
        this.appComponent.setScreenBusyState(false);
      });

      return promiseChain;
    }

    loadAddresseesClients(){
      return this.dbAccessor.retrieveAllByType("RO", ["header","items"]).then((res) => {
        if(res.success){
          res.docs.forEach((resDoc)=>{
            this.ROAddressees.add(resDoc.header.addressee);
            this.ROClients.add(resDoc.header.client.name);
            resDoc.items.forEach((item) => {
              this.publicationsSet.add(item.publication);
            });
          });
          this.selectedDocumentType = "RO";
          this.documentTypeSelected("RO");
          this.publicationsList = Array.from(this.publicationsSet); 
          this.publicationsFilteredList = this.publicationsList;
        }
      }).then(() => {
        return this.dbAccessor.retrieveAllByType("Invoice", ["header"]).then((res) => {
          if(res.success){
            res.docs.forEach((resDoc)=>{
              this.invoiceClients.add(resDoc.header.addressee.name);
            });
          }
        });
      });
    }

    documentTypeSelected(value){
      let fieldReq;
      if(value == "RO"){
        this.customersList = Array.from(this.ROClients);
        this.customersFilteredList = this.customersList;
      }
      else if(value == "Invoice"){
        this.customersList = Array.from(this.invoiceClients);
        this.customersFilteredList = this.customersList;
      }
      this.publicationSearchValue = '';
      this.customerSearchValue = '';
      
      this.onCustomerListItemClick(null);
      this.onPublicationListItemClick(null);
    }


    executeQuery(){
      let queryParams = {
        findParam : {}
      };
      this.isQuerying = true;
      if(this.selectedDocumentType == "RO"){
        queryParams.findParam["type"] = {
          $eq : "RO"
        };
        
        if(this.selectedCustomersArr && this.selectedCustomersArr.length > 0){
          queryParams.findParam["header.client.name"] = {
              $in : this.selectedCustomersArr
          } 
        }
        if(this.selectedPublicationsArr && this.selectedPublicationsArr.length > 0){
          queryParams.findParam["items"] = {
            $elemMatch: {
              publication : {
                $in: this.selectedPublicationsArr
              }
            }
          }
          // queryParams.findParam["items.publication"] = {
          //   $eq :this.selectedPublication
          // }
          /*queryParams.findParam["header.publication.name"] = {
              $eq : this.selectedPublication
          } */
        }
      }
      else if(this.selectedDocumentType == "Invoice" || this.selectedDocumentType == "Proforma"){
        
        queryParams.findParam["type"] = {
          $eq : this.selectedDocumentType
        };
        queryParams.findParam["header.isDraft"] = {
          $eq : false
        };
        if(this.selectedCustomersArr && this.selectedCustomersArr.length > 0){
          queryParams.findParam["header.addressee.name"] = {
              $in : this.selectedCustomersArr
          }
        }
      }

      // Get date range
      let dateLower, dateHigher;
      if(this.selectedDateRangeOption == "currYear" || this.selectedDateRangeOption == "lastYear" ){
        dateHigher = new Date();
        let lowerYear;
        if(dateHigher.getMonth() >= 3) // if current month is april or later
          lowerYear = dateHigher.getFullYear(); // starting year is current year
        else
          lowerYear = dateHigher.getFullYear() - 1; // starting year is last year

        if(this.selectedDateRangeOption == "lastYear"){
          lowerYear -= 1;
          dateHigher = new Date(lowerYear + 1, 2, 31);
        }
        dateLower = new Date(lowerYear, 3, 1);  // starting date is 1st april
      }
      else if(this.selectedDateRangeOption == "currMonth"){
        dateHigher = new Date();
        dateLower = new Date(dateHigher.getFullYear(), dateHigher.getMonth(), 1);
      }
      else if(this.selectedDateRangeOption == "dateRange"){
        dateLower = this.startDate;
        dateHigher = this.endDate;
      }

      if(this.selectedDateRangeOption != "none"){
        queryParams.findParam["header.billDate"] = {
          $gte : dateLower,
          $lte : dateHigher
        }
      }
      
      this.isScreenBusy = true;

      return this.dbAccessor.retrieve(queryParams).then((resultSet) => {
        this.fillReportsBlock(resultSet, this.selectedDocumentType, this.selectedCustomer, this.selectedPublication, [dateLower, dateHigher]);
        if(this.selectedDocumentType == "RO"){
          this.displayROList = true;
          this.displayInvoiceList = false;
          this.isQuerying = false;
          return this.fillROTable(resultSet);
        }
        else if(this.selectedDocumentType == "Invoice" || this.selectedDocumentType == "Proforma"){
          this.displayROList = false;
          this.displayInvoiceList = true;
          this.isQuerying = false;
          return this.fillInvoiceTable(resultSet);
        }
      }).catch((ex) => {
        this.isQuerying = false;
      });
    }

    fillReportsBlock(resultSet, docType, client, publication, dateRangeArr) {
      this.reportObj = {
        documentType : "",
        client : "",
        publication : "",
        dateRangeStr : "",
        recordCount : "",
        grossAmount : 0,
        discounts : 0,
        surcharges : 0,
        gstAmount : 0,
        netAmount : 0
      }

      this.reportObj.documentType = docType;
      this.reportObj.client = client || "";
      this.reportObj.publication = publication || "";
      this.reportObj.recordCount = resultSet.docs.length;
      if(dateRangeArr[0] == null || dateRangeArr[1] == null){
        this.reportObj.dateRangeStr = "- No filter -";
      }
      else{
        this.reportObj.dateRangeStr += DateUtil.getFormattedDate(dateRangeArr[0]) + ' - ' + DateUtil.getFormattedDate(dateRangeArr[1]);
      }

      resultSet.docs.forEach((doc) => {
        if(docType == "RO"){
          //this.reportObj.client = doc.header.client.name;
        }
        else if(docType == "Invoice"){
          //this.reportObj.client = doc.addressee.name
        }
        this.reportObj.grossAmount += doc.totalObj.grossAmount;
        doc.totalObj.additionalComponents.forEach(addComp => {
          if(addComp.valueSign == '-')
            this.reportObj.discounts -= addComp.value;
          else if(addComp.valueSign == '+')
            this.reportObj.surcharges += addComp.value;
        });
        this.reportObj.gstAmount += doc.totalObj.totalGST.value;
        this.reportObj.netAmount += doc.totalObj.netAmount;
      });
    }

    applySearch(value){

    }

    filterCustomers($event){
      if(!$event.srcElement.value || $event.srcElement.value.length == 0)
        this.customersFilteredList = this.customersList;
      else
        this.customersFilteredList = this.customersList.filter((el) => el.toLowerCase().includes($event.srcElement.value.toLowerCase()));
    }

    customerListCheckboxChange($event, customer){
      if($event.checked){
        this.selectedCustomers[customer] = true;
      }
      else
        this.selectedCustomers[customer] = false;
      this.updateSelectedCustomersStr();
    }

    onCustomerListItemClick(selectedCustomerEntry){
      if(selectedCustomerEntry)
        this.selectedCustomers[selectedCustomerEntry] = !this.selectedCustomers[selectedCustomerEntry];
      else
        this.selectedCustomers = {};
      this.updateSelectedCustomersStr();
    }

    updateSelectedCustomersStr(){
      let count = 0;
      this.selectedCustomersArr = [];
      Object.keys(this.selectedCustomers).forEach((key) => {
        if(this.selectedCustomers[key]){
          count++;
          this.selectedCustomersArr.push(key);
        }
      });
      if(count == 0)
        this.selectedCustomersStr = "- No filter -";
      else if(count == 1)
        this.selectedCustomersStr = "1 item";
      else
        this.selectedCustomersStr = count + " items";
    }

    customerMenuOpened(){
      setTimeout(() => {
        this.customerSearchInput.nativeElement.focus();
      });
    }
    
    filterPublications($event){
      if(!$event.srcElement.value || $event.srcElement.value.length == 0)
        this.publicationsFilteredList = this.publicationsList;
      else
        this.publicationsFilteredList = this.publicationsList.filter((el) => el.toLowerCase().includes($event.srcElement.value.toLowerCase()));
    }
    
    publicationListCheckboxChange($event, publication){
      if($event.checked){
        this.selectedPublications[publication] = true;
      }
      else
        this.selectedPublications[publication] = false;
      this.updateSelectedPublicationsStr();
    }

    onPublicationListItemClick(selectedPublicationEntry){
      if(selectedPublicationEntry)
        this.selectedPublications[selectedPublicationEntry] = !this.selectedPublications[selectedPublicationEntry];
      else
        this.selectedPublications = {};
      this.updateSelectedPublicationsStr();
    }

    updateSelectedPublicationsStr(){
      let count = 0;
      this.selectedPublicationsArr = [];
      Object.keys(this.selectedPublications).forEach((key) => {
        if(this.selectedPublications[key]){
          count++;
          this.selectedPublicationsArr.push(key);
        }
      });
      if(count == 0)
        this.selectedPublicationsStr = "- No filter -";
      else if(count == 1)
        this.selectedPublicationsStr = "1 item";
      else
        this.selectedPublicationsStr = count + " items";
    }

    publicationMenuOpened(){
      setTimeout(() => {
        this.publicationSearchInput.nativeElement.focus();
      });
    }

    remove_linebreaks( str ) {
      return str.replace( /[\r\n]+/gm, "" );
    }
    
    saveCSV(){
      let csvContent = "data:text/csv;charset=utf-8,";
      let delimiter = ",";
      let headerContentArray;
      if(this.selectedDocumentType == "RO")
        headerContentArray = ["Row No.","RO No.","Client Name","Dimensions","RO Date","Insertion Date","Publication","Release Type","Invoice Generated?","Net Amount"];
      else if(this.selectedDocumentType == "Invoice")
        headerContentArray = ["Row No.","Invoice No.","Client Name","Dimensions","Invoice Date","Insertion Date","Publication","Release Type","Payment Made?","Net Amount"];
      let headerContent = headerContentArray.join(delimiter);
      csvContent += headerContent + "\r\n";
      let indx = 1;
      if(this.selectedDocumentType == "RO"){
        this.allROsArray.forEach(RORow => {
          let rowContent = "";
          rowContent += indx++ + delimiter;
          rowContent += RORow.billNo + delimiter;
          rowContent += '"' + this.remove_linebreaks(RORow.clientName) + '"' + delimiter;
          rowContent += RORow.dimensions + delimiter;
          rowContent += DateUtil.getDateText(RORow.billDate) + delimiter;
          rowContent += RORow.itemDateRangeStr + delimiter;
          rowContent += '"' +  this.remove_linebreaks(RORow.publicationsStr) + '"' + delimiter;
          rowContent += RORow.releaseType + delimiter;
          rowContent += (RORow.isInvoiceGenerated || "FALSE") + delimiter;
          rowContent += RORow.netAmount + delimiter;
          csvContent += rowContent + "\r\n";
        });
      }
      else if(this.selectedDocumentType == "Invoice"){
        this.allInvoicesArray.forEach(InvoiceRow => {
          let rowContent = "";
          rowContent += indx++ + delimiter;
          rowContent += InvoiceRow.billNo + delimiter;
          rowContent += '"' + this.remove_linebreaks(InvoiceRow.clientName) + '"' + delimiter;
          rowContent += InvoiceRow.dimensions + delimiter;
          rowContent += DateUtil.getDateText(InvoiceRow.billDate) + delimiter;
          rowContent += InvoiceRow.itemDateRangeStr + delimiter;
          rowContent += '"' +  this.remove_linebreaks(InvoiceRow.publicationsStr) + '"' + delimiter;
          rowContent += InvoiceRow.releaseType + delimiter;
          rowContent += '' + delimiter;
          //rowContent += (InvoiceRow.isPaymentMade || "FALSE") + delimiter;
          rowContent += InvoiceRow.netAmount + delimiter;
          csvContent += rowContent + "\r\n";
        });
      }

      let encodedUri = encodeURI(csvContent);
      let link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "my_data.csv");
      document.body.appendChild(link);
      
      link.click(); 
    }

    onRefreshClick(){
      //this.loadContents();
      //this.ROListTableElement.renderRows();
    }
    
}
